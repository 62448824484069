import React from 'react'
import Logo from '../Assets/PLdev.png';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className='py-8'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
          <h1></h1>
      </div>
    </div>
  </footer>
  )
}

  

  
