import React, { Component } from 'react'
//Images
import Image from '../Assets/meTest.png'
//Icons
import { FaGithub, FaLinkedin, FaFilePdf } from 'react-icons/fa'
//type Animation
import { TypeAnimation } from 'react-type-animation'
//motion
import { motion } from 'framer-motion'
import { fadeIn } from '../variants.js'
import { Link } from 'react-router-dom';

import resumePDF from '../Assets/Patrick_Resume.pdf'

export default class Banner extends Component {
  render() {
    return (
      <section
        className="min-h-screen"
        id="home"
      >
        <div className="container mx-auto">
          <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
            {/* Text */}
            <div className="flex-1 text-center font-secondary lg:text-left ">
              <motion.h1
                variants={fadeIn('up', 0.3)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
              >
                PATRICK <span>LEIMER</span>
              </motion.h1>
              <motion.div
                variants={fadeIn('up', 0.4)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className="mb-6 text-[36px] lg:text-[56px] font-secondary font-semibold uppercase leading-[1]">
                <TypeAnimation
                  sequence={[
                    'Software dev',
                    2000,
                    'Computer Scientist',
                    2000,
                    'Student',
                    2000,
                  ]}
                  speed={50}
                  className="text-gradient"
                  wrapper="span"
                  repeat={Infinity}
                />
              </motion.div>
              {/* <motion.p 
                variants={fadeIn('up', 0.5)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7}} 
                className="mb-8 max-w-lg mx-auto lg:mx-0">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages, and more recently with
              </motion.p> */}
              <div className='flex-col flex lg:flex-row lg:gap-x-4 gap-y-2'>
                <motion.div
                  variants={fadeIn('up', 0.6)}
                  initial="hidden"
                  whileInView={'show'}
                  viewport={{ once: false, amount: 0.1 }}
                  className="flex max-w-max p-2 items-center mb-12 mx-auto lg:mx-0 bg-white shadow-2xl hover:bg-opacity-50 bg-opacity-20 rounded-md">

                  <Link
                    to="/work"
                    className='cursor-pointer'>
                    <a href="" className=" text-white btn-link">My Projects</a>
                  </Link>
                </motion.div>

                <motion.div
                  variants={fadeIn('up', 0.6)}
                  initial="hidden"
                  whileInView={'show'}
                  viewport={{ once: false, amount: 0.1}} >
                  {/* Socials */}
                  <div className="flex text-[35px] gap-x-6 max-w-max mx-auto lg:mx-0 ">
                    <a className='cursor-pointer hover:text-blue-900'
                      href="https://www.linkedin.com/in/Patrick-LeimerE/" target="_blank">
                      <FaLinkedin />
                    </a>
                    <a className='cursor-pointer hover:text-[#6f42c1]'
                      href="https://github.com/PatrickLeimer" target="_blank">
                      <FaGithub />
                    </a>
                    <a className='cursor-pointer hover:text-[#FF56F6]'
                      target="_blank" rel="noopener noreferrer"
                      href={resumePDF}>
                        
                      <FaFilePdf />
                    </a>
                  </div>
                </motion.div>

              </div>

            </div>
            {/* Image */}
            <motion.div
              variants={fadeIn('down', 0.5)}
              initial="hidden"
              whileInView={'show'}
              className="hidden lg:flex justify-center flex-1 max-w-xs lg:max-w-lg">
              <img src={Image} alt="" className="rounded-xl"></img>
            </motion.div>
          </div>
        </div>
      </section>
    )
  }
}
