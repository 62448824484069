import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';

export default function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const navigate = useNavigate();

  // Validation logic for each form field with its character limit
  const validateForm = (formData) => {
    const errors = {};
    const firstName = formData.get('FirstName');
    const lastName = formData.get('LastName');
    const email = formData.get('Email');
    const message = formData.get('Message');

    // Check each field length and set the corresponding error
    if (firstName.length > 25) {
      errors.firstName = 'First Name cannot exceed 25 characters';
    }
    if (lastName.length > 25) {
      errors.lastName = 'Last Name cannot exceed 25 characters';
    }
    if (email.length > 40) {
      errors.email = 'Email cannot exceed 40 characters';
    }
    if (message.length > 200) {
      errors.message = 'Message cannot exceed 200 characters';
    }

    // If errors object is not empty, return false and set error messages
    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors);
      return false;
    }

    // Clear errors if no issues
    setErrorMessage({});
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = event.target;
    const formData = new FormData(form);

    if (!validateForm(formData)) { //Validate
      setIsSubmitting(false);
      return;
    }

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      });

      navigate('/contactSubmitted');
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <section className="min-h-screen" id="contact">
      <div className="container mx-auto pb-16">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <div className="flex-1 flex justify-center lg:justify-start items-center">
            <motion.div
              variants={fadeIn('right', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
            >
              <h4 className="text-lg lg:text-xl font-medium mb-2 tracking-wide">
                Just a click away from working together!
              </h4>
              <h2 className="text-[40px] lg:text-[80px] leading-none mb-8 lg:mb-12">
                <TypeAnimation
                  sequence={['Get in touch', 1250, 'with Patrick', 750, 'right now!', 750]}
                  speed={10}
                  className="theColorOfText"
                  wrapper="span"
                  repeat={Infinity}
                />
              </h2>
            </motion.div>
          </div>

          {/* form */}
          <motion.div
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <form
              name="Messag"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              className="flex flex-col gap-y-4 lg:gap-y-6 p-6 lg:p-8 rounded-2xl shadow-lg text-[#00356B]"
            >
              <input type="hidden" name="form-name" value="Messag" action="/" />

              {/* First Name */}
              <input
                type="text"
                name="FirstName"
                required
                className="bg-white rounded-md border-2 py-3 px-4 outline-none w-full placeholder:text-[#7597de] focus:border-[#00356B] transition-all"
                placeholder="First Name"
              />
              {errorMessage.firstName && (
                <p className="text-[#FF56F6] text-sm">{errorMessage.firstName}</p>
              )}

              {/* Last Name */}
              <input
                type="text"
                name="LastName"
                required
                className="bg-white rounded-md border-2 py-3 px-4 outline-none w-full placeholder:text-[#7597de] focus:border-[#00356B] transition-all"
                placeholder="Last Name"
              />
              {errorMessage.lastName && (
                <p className="text-[#FF56F6] text-sm">{errorMessage.lastName}</p>
              )}

              {/* Email */}
              <input
                type="email"
                name="Email"
                required
                className="bg-white rounded-md border-2 py-3 px-4 outline-none w-full placeholder:text-[#7597de] focus:border-[#00356B] transition-all"
                placeholder="Email"
              />
              {errorMessage.email && (
                <p className="text-[#FF56F6] text-sm">{errorMessage.email}</p>
              )}

              {/* Message */}
              <textarea
                name="Message"
                required
                className="bg-white rounded-md border-2 px-4 py-6 outline-none w-full placeholder:text-[#7597de] focus:border-[#00356B] transition-all resize-none mb-4 lg:mb-6"
                placeholder="Message"
              />
              {errorMessage.message && (
                <p className="text-[#FF56F6] text-sm">{errorMessage.message}</p>
              )}

              <button
                type="submit"
                className="btn btn-lg w-full lg:w-[250px] bg-[#7597de] text-white py-3 rounded-lg"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send'}
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
